body {
  background-color: #f7f7f7 !important;
}
.not_found_logo {
  background-image: url('./assets/images/logo.png');
  background-size: auto 72%; background-position: center;
  background-repeat: no-repeat; background-color: inherit !important;
  margin-top: 12%; margin-bottom: 0; height: 24vh; box-shadow: none !important;
}
.not_found_text {
  text-align: center; padding: 4%; font-size: 1.6em; color: #232C39;
  background-color: inherit !important; box-shadow: none !important;
}
.not_found_button {
  text-align: center; background-color: inherit !important;
  box-shadow: none !important;
}