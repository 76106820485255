.clean_paper {
  background-color: rgba(0,0,0,0) !important; box-shadow: none !important;
  color: #232C39 !important;
}
.admin_add_officer_btn {
  text-decoration: none; background: #232C39; color: white;
  padding: 4px 12px; border-radius: 6px;
}
.admin_officers_card_p {
  padding: 1% 4%; font-size: 1.2em;
}
.admin_metrics_card {
  margin: 3%; padding: 3%;
}
.sort_packages {
  text-decoration: none; background: #232C39; color: white;
  padding: 7px 12px; border-radius: 6px; width: 100px; margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.variables-content {
  margin: 40px 0 0 20px;
}