.admin_signin_main_image {
  width: 100%; min-height: 100vh; height: auto;
  background-image: url('../../../assets/images/signup_image.png');
  background-size: cover; background-position: center;
  background-repeat: no-repeat;
  @media screen and (max-width: '1024px') {
    min-height: 0; height: 0;
  }
}
.admin_signin_form {
  width: 60%; margin: 2% auto;
  @media screen and (max-width: '1024px') {
    width: 90%;
  }
  button {
    margin: 3%; margin-left: 40%;
  }
}
.admin_signin_form_title {
  color: #303E52; text-align: center; font-size: 1.2em;
}
.admin_signin_form_logo {
  width: 100%; height: 16vh; margin-top: 6vh;
  background-image: url('../../../assets/images/logo.png');
  background-size: auto 80%; background-position: center;
  background-repeat: no-repeat;
}

.linear_progress {
  position: fixed; width: 100%; top: 0; left: 0;
}