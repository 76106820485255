.signin_container_main {
	display: flex; flex-wrap: wrap; align-items: center; justify-content: center;
	min-height: 100vh; background: white;
	.signin_image_main {
		width: 50%; min-height: 100vh;
		background-image: url('../../../assets/images/signup_image.png');
		background-size: auto 100%; background-repeat: no-repeat;
		@media screen and (max-width: '1024px') {
			width: 0; background-size: auto 0%;
		}
	}
	.signin_form_main {
		width: 50%; background: white;
		@media screen and (max-width: '1024px') {
			width: 100%;
		}		
		.signin_form_logo {
			width: 100%; height: 12vh;
			background: url('../../../assets/images/logo.png');
			background-size: auto 100%; background-repeat: no-repeat;
			background-position: center;
		}
		.signin_form {
			width: 52%; padding: 6% 24%; text-align: center;
			@media screen and (max-width: '1024px') {
				width: 82%; padding: 2% 9%;
			}
			.signin_form_title {
				width: 88%; padding: 16% 6%; font-size: 1.2em;
				color: rgb(80,80,80);
			}
			.signin_label {
				font-size: 0.9em; text-align: left;
			}
			.signin_input {
				width: 90%; margin-bottom: 2vh; margin-right: 8%; text-align: left;
			}
			.signin_form_errors {
				width: 100%; color: red; font-size: 0.8em; text-align: center;
				padding-top: 4px;
			}
			.signin_options {
				width: 100%; padding-top: 8px;
				a {
					text-decoration: underline; color: #232C39;
				}
			}
		}
	}
}
.signin_linear_progress {
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
}