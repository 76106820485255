.accounts_main_image {
	width: 100%; min-height: 100vh; height: 100%;
	background: url('../../assets/images/signup_image.png');
	background-position: center; background-repeat: no-repeat;
	background-size: cover;
	@media screen and (max-width: '1024px') {
		min-height: 0; height: 0;
	}
}
.accounts_main_form {
	width: 92%; padding: 4%; box-shadow: none !important;
	background: rgba(0,0,0,0) !important;
	.logo {
		width: 96%; height: 12vh; padding: 2%; margin-top: 8vh;
		background: url('../../assets/images/logo.png');
		background-position: center; background-repeat: no-repeat;
		background-size: auto 100%;
	}
	.title {
		width: 88%; padding: 6%; text-align: center; color: #232C39;
		font-size: 1.2em;
	}
	.verify_actions_button {
		width: 88%; padding: 6%; text-align: center; color: #232C39;
		font-size: 1.2em;
		button {
			background: #232C39 !important; color: white !important;
		}	
	}
}