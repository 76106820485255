.index_shadow_paper {
  padding: 3% !important; background: inherit !important;
  box-shadow: 0px 8px 8px 0 #efeffb !important;
}

.home_app_bar_logo {
  height: 56px;
  background-image: url('../assets/images/logo.png');
  background-position: center; background-repeat: no-repeat;
  background-size: auto 90%;
  width: 120px;
  @media screen and (max-width: 1024px) {
    background-size: auto 64%;
    width: 80px;
  }
}
.swipeable_sect {
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
}
.home_toggle_icon {
  display: flex;
  align-items: center;
  @media screen and (max-width: 1024px) {
    width: 400px;
  }
}
.index_grid_main {
  height: 100vh;
  background-image: url('../assets/backgrounds/main.png');
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;
  position: relative;
  .main_paper { padding: 4%; box-shadow: none !important;
    background: rgba(239,239,251,0) !important;
    width: 60%;
    max-width: 60%;
    padding-top: 280px;
    padding-left: 65px;
    @media screen and (max-width: '800px') {
      width: 100%;
      max-width: 100%;
      padding-left: 30px;
      padding-top: 0;
      position: relative;
    }
    @media screen and (max-width: '420px') {
      width: 100%;
      max-width: 100%;
      position: absolute;
      top: -15px;
      padding-top: 0;
      padding-left: 20px;
    }
  } 
  @media screen and (max-width: 800px) {
    background-size: auto 43%;
    margin-top: 20px;
  }
  @media screen and (max-width: 420px) {
    height: 75vh;
    background-size: auto 37%;
    margin-top: 20px;
  }
}
.index_grid_calc {
  background-image: url('../assets/backgrounds/main.png');
  background-repeat: no-repeat; background-position: top right;
  background-size: auto 100%;
  width: 100%;
  @media screen and (max-width: '1024px') {
    background-size: 100% auto;
    margin-top: 20px;
  }
  .calc_paper { padding: 5% 4%; color: white; box-shadow: none !important;
    background: inherit !important;

  }
  @media screen and (max-width: 1024px) {
    background-size: auto 100%;
  }
}
.index_icns_ftrs {
  height: 64px !important; box-shadow: none !important;
  background-color: rgba(0,0,0,0) !important; 
  background-repeat: no-repeat; background-position: center;
  background-size: auto 80%;
}
.sf_protect {
  background-image: url('../assets/backgrounds/icon_protection.png');
}
.sf_cc {
  background-image: url('../assets/backgrounds/icon_ship_pro.png');
}
.sf_bulk {
  background-image: url('../assets/backgrounds/icon_bulk_up.png');
}
.sf_perp {
  background-image: url('../assets/backgrounds/icon_per_profile.png');
}
.sf_notf {
  background-image: url('../assets/backgrounds/icon_notification.png');
}
.sf_track {
  background-image: url('../assets/backgrounds/icon_tracking.png');
}
.sf_history {
  background-image: url('../assets/backgrounds/icon_history.png');
}
.sf_delivery {
  background-image: url('../assets/backgrounds/icon_delivery.png');
}
.sf_shipping {
  background-image: url('../assets/backgrounds/icon_shipping.png');
}
// Footer
.index_footer {
  background-color: #232C39 !important; padding: 6vh !important;
  .footer_logo {
    box-shadow: none !important; border-radius: 0 !important;
    height: 128px; background: url('../assets/images/logo.png');
    background-position: center; background-repeat: no-repeat;
    background-size: auto 80%;
  }
}

.slider {
  display: flex;
  justify-content: space-around;
  font-family: sans-serif;
  box-shadow: 0px 10px 10px 8px #d0d0d0;
  height: 70px;
  color : rgb(35,44,57);
  width: 1200px;

  @media screen and (max-width: 1024px) and (min-width: 421) {
    flex-wrap: wrap;
    margin-top: 20px;
    height: 100%;
  }

  @media screen and (max-width: 420px) {
    flex-wrap: wrap;
    margin-top: 0;
  }

  .vprop {
    width: 33%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 1.4em;
    font-weight: 600;
    @media screen and (max-width: 1024px) {
      width: 100%;
      height: 70px;
      justify-content: flex-start;
      padding-left: 30px;
      // can be removed
      font-size: 1.2em;
    }
  }

  .vsects {
    width: 67%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .sect1 {
      display: flex;
      align-items: center;
      h1 {
        font-size: 3em;
        padding-right: 15px;
      }
      span {
        word-spacing: 1px;
      }
      .sect-border {
        border-right: 2px solid #d0d0d0;
        height: 45px;
        padding-left: 55px;
      }
    }
    @media screen and (max-width: 1024px) {
      width: 100%;
      flex-wrap: wrap;
      .sect1 {
        width: 100%;
        height: 70px;
        padding-left: 30px;
      }
      .sect-border {
        border-right: none !important;
      }
    }
  }
}

.slider h2 {
  color: #ffffff;
  font-size: 2.1em;
}