.invoice_card {
  width: 90%; margin: 5% auto; border-radius: 12px !important;
  padding: 3% !important;
  .invoice_head {
    padding: 3%; color: #232C39; border-radius: 0; box-shadow: none;
  }
  .invoice_card_to_address {
    padding: 4% !important; background: whitesmoke !important;
    padding-left: 8% !important;
  }
  .invoice_summary_list {
    box-shadow: none; margin-left: 8%; padding-bottom: 2%;
  }
  .invoice_items_title {
    box-shadow: none; margin-left: 2%; margin-top: 4%;
    font-size: 1.6em; color: darkgray;
  }
  .invoice_summary_paper {
    width: 24%; margin-left: 74%; padding: 2%; color: darkslategray;
    border-radius: 0; box-shadow: none;
  }
  .invoice_options_paper {
    width: 96%; padding: 2%; color: darkslategray;
    border-radius: 0; box-shadow: none; text-align: right;
    .invoice_options_paper_btn {
      color: white; margin: 0 1%; padding: 2px 6px;
    }
  }
}

.invoice_card_paid {
  width: 90%; margin: 5% auto; border-radius: 12px !important;
  padding: 3% !important; min-width: 780px !important;
  background-image: url('../../../assets/images/paid.png');
  background-repeat: no-repeat; background-position: 80% 35%;
  background-size: 56px auto;
  .invoice_head {
    padding: 3%; color: #232C39; border-radius: 0; box-shadow: none;
    background: rgba(0,0,0,0) !important;
  }
  .invoice_card_to_address {
    padding: 4% !important; background: whitesmoke !important;
    padding-left: 8% !important;
  }
  .invoice_summary_list {
    box-shadow: none; margin-left: 8%; padding-bottom: 2%;
  }
  .invoice_items_title {
    box-shadow: none; margin-left: 2%; margin-top: 4%;
    font-size: 1.6em; color: darkgray;
  }
  .invoice_summary_paper {
    width: 24%; margin-left: 74%; padding: 2%; color: darkslategray;
    border-radius: 0; box-shadow: none;
  }
  .invoice_options_paper {
    width: 96%; padding: 2%; color: darkslategray;
    border-radius: 0; box-shadow: none; text-align: right;
    .invoice_options_paper_btn {
      color: white; margin: 0 1%; padding: 2px 6px;
    }
  }
}

.invoice_pay_mpesa_logo {
  width: 100% !important; color: darkgreen !important;
  background-image: url('../../../assets/images/mpesa.png');
  background-repeat: no-repeat; background-position: right center;
  background-size: auto 96%; padding-bottom: 24px !important;
}

.invoice_pay_fatt_merchant_logo {
  width: 100% !important; color: #EF6B20 !important;
  background-image: url('../../../assets/images/fattmerchant.png');
  background-repeat: no-repeat; background-position: right center;
  background-size: auto 96%; padding-bottom: 24px !important;
}

.invoice_pay_credit_card__logo {
  width: 100% !important; color: darkblue !important;
  background-image: url('../../../assets/images/credit_cards.png');
  background-repeat: no-repeat; background-position: right center;
  background-size: auto 96%; padding-bottom: 24px !important;
}
